<template>
  <b-container>
    <validation-observer ref="saveCampaignForm" #default="{}">
      <b-form id="saveCampaignForm">
        <b-card class="d-flex">
          <b-row class="border rounded mx-1 mb-2 p-2 justify-content-center">
            <b-col cols="1">
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="flat-warning"
                class="btn-icon m-0"
                to="/push-campaigns"
                v-b-tooltip.hover
                title="Go Back"
              >
                <feather-icon icon="ArrowLeftCircleIcon" size="20" />
              </b-button>
            </b-col>
            <b-col cols="11" class="d-flex align-items-center">
              <h1 class="mb-0">Push Campaign</h1>
            </b-col>
          </b-row>

          <b-row class="border rounded mb-2 p-2 justify-content-center mx-1">
            <b-col cols="12" class="mr-1">
              <b-container>
                <b-row>
                  <b-col cols="4" class="mb-2">
                    <h5 class="mb-0">Campaign Details :</h5>
                    <small class="text-muted">
                      Enter Your Campaign Details.
                    </small>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Name : *"
                      label-for="h-campaign-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Campaign name"
                        rules="required|regex:^[a-zA-Z].*$"
                      >
                        <b-form-input
                          id="h-campaign-name"
                          v-model="campaignName"
                          placeholder="Campaign name"
                          :state="errors.length > 0 ? false : null"
                        />
                        <!-- @change="titleIntered" -->
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" offset="4">
                    <b-form-group
                      label="Start Date : *"
                      label-for="h-Creatives-date"
                    >
                      <!-- v-model="selectedDateTime" -->
                      <input
                        v-model="CreativesDate"
                        class="form-control"
                        :min="minDate"
                        id="datetimepicker"
                        type="datetime-local"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" offset="4">
                    <b-form-group label="Url : *">
                      <validation-provider
                        #default="{ errors }"
                        name="Domains"
                        rules="required"
                      >
                        <b-form-input
                          id="h-campaign-url"
                          v-model="campaignUrl"
                          placeholder="Campaign url"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" offset="4">
                    <label for="">Select Account : *</label>
                    <!-- v-model="value" -->
                    <v-select
                      id="account"
                      v-model="accountsSelected"
                      multiple
                      label="name"
                      :options="accountsValues"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-column flex-md-row border rounded mb-2 p-2 justify-content-center mx-1"
          >
            <b-col class="mr-2 border p-2 rounded col-12 mt-2 col-md-6">
              <b-container>
                <b-row>
                  <b-col cols="12" class="">
                    <h5 class="mb-0">Creatives Details :</h5>
                    
                  </b-col>
                  <b-col cols="12" class="">
                    <imgmng />
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Icon : "
                      label-for="h-Creatives-icon"
                    >
                      <b-form-input
                        id="h-creatives-Icon"
                        v-model="CreativesIcon"
                        placeholder="Icon"
                      />
                      <!-- <b-form-file
                        :state="Boolean(CreativesIcon)"
                        @change="tempImageInputChanged"
                      ></b-form-file>-->
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Image : "
                      label-for="h-Creatives-Image"
                    >
                      <b-form-input
                        id="h-creatives-Image"
                        v-model="CreativesImage"
                        placeholder="Image"
                      />
                      <!-- <b-form-file
                        :state="Boolean(CreativesImages)"
                        @change="tempImageInputChanged"
                      ></b-form-file> -->
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="name: *"
                      label-for="h-Creatives-name"
                    >
                      <b-form-input
                        id="h-Creatives-title"
                        v-model="CreativesTitle"
                        placeholder="title"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Description: *"
                      label-for="did"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                      >
                        <b-form-textarea
                          id="textarea"
                          v-model="CreativesDescription"
                          placeholder="Description ..."
                          rows="3"
                          max-rows="6"
                          style="overflow: hidden"
                          :state="errors.length > 0 ? false : null"
                        ></b-form-textarea>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
            <b-col class="mr-2 border p-2 rounded col-12 col-md-5 mt-2">
              <b-container
                class=" textStart"
              >
                <b-row
                  style="direction: rtl !important"
                  class="text-wrap text-break"
                >
                  <b-col cols="9" class="mt-2 d-flex justfy-content-end">
                    <h5
                      v-if="!CreativesTitle"
                      id="preview-title"
                      class="card-title font-weight-bold"
                    >
                      لوريم إيبسوم
                    </h5>
                    <h5
                      v-if="CreativesTitle"
                      id="preview-title"
                      class="card-title font-weight-bold"
                    >
                      {{ CreativesTitle }}
                    </h5>
                  </b-col>
                  <b-col cols="3" class="mt-2">
                    <div
                      id="preview-iconImage"
                      class="text-center bg-dark rounded-circle"
                      style="height: 64px"
                    >
                      <img
                        v-if="CreativesIcon"
                        class="rounded-circle"
                        style="width: 100%; height: 100%"
                        :src="CreativesIcon"
                        alt=""
                      />
                      <feather-icon
                        v-if="!CreativesIcon"
                        icon="ImageIcon"
                        class="align-middle h-100"
                        size="30"
                      />
                    </div>
                  </b-col>

                  <b-col cols="12" class="mt-1" style="direction: rtl">
                    <p
                      class="lh-lg"
                      id="preview-shortDescription"
                      v-if="!CreativesDescription"
                    >
                      لوريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن
                      الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع
                      ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي
                      منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من
                      الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل
                      أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على
                      هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة
                      والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن
                      مع إصدار رقائق "ليتراسيت" (Letraset) البلاستيكية تحوي
                      مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور
                      برامج النشر الإلكتروني مثل "ألدوس بايج مايكر" (Aldus
                      PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.
                    </p>
                    <p
                      class="lh-lg"
                      id="preview-shortDescription"
                      v-if="CreativesDescription"
                    >
                      {{ CreativesDescription }}
                    </p>
                    <div
                      id="preview-headerImage"
                      class="text-center img"
                      style="height: 10rem"
                    >
                      <!-- class="img-fluid img-responsive" -->
                      <img
                        v-if="CreativesImage"
                        style="width: 100%; height: 100%"
                        :src="CreativesImage"
                        alt=""
                      />
                      <feather-icon
                        v-if="!CreativesImage"
                        icon="ImageIcon"
                        class="align-middle h-100"
                        size="50"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row class="border rounded p-2 mx-1 mb-0">
            <b-col cols="6" offset="5">
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-success"
                @click="storeData"
                class="mr-1"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BModal,
  BCard,
  BFormSelect,
  BTable,
  BInputGroup,
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BFormFile,
  BImg,
  BForm,
  BInputGroupAppend,
  BButtonGroup,
  BCardText,
  VBTooltip,
  BPagination,
  BButton,
  VBHover,
  BContainer,
  BFormInvalidFeedback,
  BAspect,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import Editor from "@tinymce/tinymce-vue";
import imagesManagement from "@/views/ads-manager/media-management/ImagesManagement.vue";
import addOns from "@/views/ads-manager/media-management/AddOns.vue";
import { ref } from "@vue/composition-api";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormTextarea,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    BFormSelect,
    BCardText,
    BInputGroupAppend,
    BForm,
    BTable,
    BInputGroup,
    BModal,
    addons: addOns,
    imgmng: imagesManagement,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    vSelect,
    BAspect,
    editor: Editor,
    BFormInvalidFeedback,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-hover": VBHover,
    Ripple,
  },
  data() {
    return {
      accountsValues: [],
      accountsSelected: [],
      campaignName: "",
      campaignUrl: "",
      CreativesDate: "",
      CreativesIcon: null,
      CreativesImage: null,
      CreativesTitle: "",
      CreativesDescription: "",
      minDate: new Date().toISOString().slice(0, 16),
    };
  },
  mounted() {
    this.availableAccount();
  },
  methods: {
    availableAccount() {
      axios
        .get("/get-available-account")
        .then(({ data }) => {
          data.accounts.forEach((acc) => {
            this.accountsValues.push({
              id: acc.id,
              name: acc.account_name,
            });
          });
        })
        .catch(console.error);
    },
    storeData() {
      
      const pushData = new FormData();
      pushData.append("campaign_name", this.campaignName);
      pushData.append("url", this.campaignUrl);
      pushData.append("creatives_date", this.CreativesDate);
      pushData.append("creatives_icon", this.CreativesIcon);
      pushData.append("creatives_image", this.CreativesImage);
      pushData.append("creatives_name", this.CreativesTitle);
      pushData.append("creatives_description", this.CreativesDescription);
      this.accountsSelected.forEach((account) => {
        pushData.append("account_ids[]", account.id);
        pushData.append("account_names[]", account.name);
      });
      axios
        .post("/store-push-campaign", pushData)
        .then((response) => {
          if (response.data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (response.data.status == "success") {
            this.showToast(
              "success",
              "SaveIcon",
              "Done",
              response.data.message
            );
            router.push({ name: "push-campaigns" });
          } else if (response.data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              response.data.message
            );
          }
          
        })
        .then(() => this.availableAccount())
        .catch(console.error);
    },

    tempImageInputChanged(e) {
      this.CreativesIcon = e.target.files[0];
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.media-add {
  position: absolute;
  top: 16px;
  right: -7px;
}
.media-add button {
  border: none !important;
  background-color: none !important;
  outline: none !important;
}

.media-add button > svg {
  color: white;
  font-size: large;
}
</style>
